import React from "react";
import {useMsal} from "@azure/msal-react";
import {Button} from "@mui/material";
import {loginRequest} from "./AuthConfig";


/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 */
export const SignInButton = () => {
    const {instance} = useMsal();

    const handleLogin = (loginType) => {
        if (loginType === "redirect") {
            instance.loginRedirect(loginRequest).catch(e => {
                console.log(e);
            });
        }
    }
    return (
        <Button variant="contained" color="success" onClick={() => handleLogin("redirect")}>Sign in</Button>
    );
}