import React, {Component} from 'react';

export class IdentifiersSearch extends Component {
    static displayName = IdentifiersSearch.name;

    constructor(props) {
        super(props);
        this.state = {identifier: "", searchResults: [], loading: true};

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({identifier: event.target.value});
    }

    handleSubmit(event) {
        this.searchOffers();
        event.preventDefault();
    }

    static renderOffersTable(searchResults) {
        return (
            <table className='table table-striped' aria-labelledby="tabelLabel">
                <thead>
                <tr>
                    <th>Identifier</th>
                    <th>Identifier Type</th>
                    <th>Party Name</th>
                    <th>Party Street</th>
                    <th>Party Role</th>
                    <th>Offer Code</th>
                    <th>Offer Created</th>
                    <th>Offer Expires</th>
                    <th>Offer Price</th>
                    <th>County</th>
                    <th>Apn</th>
                    <th>Acres</th>
                    <th>Url</th>
                </tr>
                </thead>
                <tbody>
                {searchResults.map(offer =>
                    <tr key={offer.id}>
                        <td>{offer.identifier}</td>
                        <td>{offer.identifierType}</td>
                        <td>{offer.partyName}</td>
                        <td>{offer.partyStreet}</td>
                        <td>{offer.partyRole}</td>
                        <td>{offer.offerCode}</td>
                        <td>{offer.createdDate}</td>
                        <td>{offer.expirationDate}</td>
                        <td>{offer.offerPrice}</td>
                        <td>{offer.propertyCounty}</td>
                        <td>{offer.identifierOne}</td>
                        <td>{offer.propertyAcres}</td>
                        <td><a href={offer.mapUrl}>Link</a></td>
                    </tr>
                )}
                </tbody>
            </table>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Ready for Search...</em></p>
            : IdentifiersSearch.renderOffersTable(this.state.searchResults);

        return (
            <div>
                <h1>Identifiers Search</h1>

                <p>Search property identifiers such as apn, legal descrption, geo id.</p>
                <p>(Use % as a wildcard and | to combine multiple search terms.)</p>

                <form id="identifierForm" onSubmit={this.handleSubmit}>
                    <label>
                        Land Identifier:&nbsp;&nbsp;
                        <input type="text" name="landIdentifier" value={this.state.identifier}
                               onChange={this.handleChange} style={{width: "370px"}}/>
                    </label>
                    &nbsp;&nbsp;
                    <input type="submit" value="Search"/>
                </form>

                <br/>

                {contents}

            </div>
        );
    }

    async searchOffers() {
        const uri = 'offers/identifiers-search';
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({searchTerms: this.state.identifier})
        };
        
        const response = await fetch(uri, requestOptions);
        const data = await response.json();
        this.setState({searchResults: data, loading: false});
    }
}