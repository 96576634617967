import {Home} from "./pages/Home";
import {NamesSearch} from "./pages/NamesSearch";
import {IdentifiersSearch} from "./pages/IdentifiersSearch";
import {CodesSearch} from "./pages/CodesSearch";


const AppRoutes = [
    {
        index: true,
        element: <Home/>
    },
    {
        path: '/names-search',
        element: <NamesSearch/>
    },
    {
        path: '/identifiers-search',
        element: <IdentifiersSearch/>
    },
    {
        path: '/codes-search',
        element: <CodesSearch/>
    }
];

export default AppRoutes;
