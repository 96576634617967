import React, {Component} from 'react';

export class CodesSearch extends Component {
    static displayName = CodesSearch.name;

    constructor(props) {
        super(props);
        this.state = {codes: "", searchResults: [], loading: true};

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({codes: event.target.value});
    }

    handleSubmit(event) {
        this.searchOffers();
        event.preventDefault();
    }

    static renderOffersTable(searchResults) {
        return (
            <table className='table table-striped' aria-labelledby="tabelLabel">
                <thead>
                <tr>
                    <th>Offer Code</th>
                    <th>Party Name</th>
                    <th>Party Street</th>
                    <th>Offer Created</th>
                    <th>Offer Expires</th>
                    <th>Land County</th>
                    <th>Apn</th>
                    <th>Acres</th>
                    <th>Url</th>
                </tr>
                </thead>
                <tbody>
                {searchResults.map(offer =>
                    <tr key={offer.id}>
                        <td>{offer.offerCode}</td>
                        <td>{offer.partyName}</td>
                        <td>{offer.partyStreet}</td>
                        <td>{offer.createdDate}</td>
                        <td>{offer.expirationDate}</td>
                        <td>{offer.propertyCounty}</td>
                        <td>{offer.identifierOne}</td>
                        <td>{offer.propertyAcres}</td>
                        <td><a href={offer.mapUrl}>Link</a></td>
                    </tr>
                )}
                </tbody>
            </table>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Ready for Search...</em></p>
            : CodesSearch.renderOffersTable(this.state.searchResults);

        return (
            <div>
                <h1>Codes Search</h1>

                <p>Search offer codes using different letter variations.</p>
                <p>(Use % as a wildcard and | to combine multiple search terms.)</p>

                <form onSubmit={this.handleSubmit}>
                    <label>
                        Offer Codes:&nbsp;&nbsp;
                        <input type="text" name="codes" value={this.state.codes}
                               onChange={this.handleChange} style={{width: "370px"}}/>
                    </label>
                    &nbsp;&nbsp;
                    <input type="submit" value="Search"/>
                </form>

                <br/>

                {contents}

            </div>
        );
    }

    async searchOffers() {
        const uri = 'offers/codes-search';
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({searchTerms: this.state.codes})
        };

        const response = await fetch(uri, requestOptions);
        const data = await response.json();
        this.setState({searchResults: data, loading: false});
    }
}