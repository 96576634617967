import React, {Component} from 'react';

export class Home extends Component {
    static displayName = Home.name;

    render() {
        const appName = "Operations App";

        return (
            <div>
                <h1>{appName}</h1>
                <h4>Welcome the {appName}. Unauthorized use is strictly prohibited.</h4>
                <p>Please choose a page from the menu above.</p>
            </div>
        );
    }
}
